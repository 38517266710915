import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/josh/Code/hub/joshayoung_website/src/components/layout-markdown.js";
import Image from "../components/image";
import Cert from "../components/cert";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`My Certifications`}</h1>
    <p>{`Below I have included a list of certifications that I have attained over the past few years.
Many of these certs required passing more than one test. I have included a short summary of each certification along with a link to a better explanation of each one. See all of my current CompTIA certs listed `}<a href='https://www.youracclaim.com/users/joshua-young.5eb07482/badges'>{`here`}</a>{` as well.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#comptia-security"
        }}>{`CompTIA Security+`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#ceh"
        }}>{`Certified Ethical Hacker (C|EH)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#pce"
        }}>{`Zend Certified PHP Engineer (ZCPE)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#network"
        }}>{`CompTIA Network+`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#professional"
        }}>{`CIW Web Development Professional`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#database"
        }}>{`CIW Database Design Specialist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#perl"
        }}>{`CIW Perl Specialist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#javascript"
        }}>{`CIW JavaScript Specialist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#linux"
        }}>{`CompTIA Linux+`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#suse"
        }}>{`SUSE Certified Linux Administrator 11`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#lpci"
        }}>{`LPIC-1`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#aplus"
        }}>{`CompTIA A+`}</a></li>
    </ul>
    <h3><a name='comptia-security'></a>{`CompTIA Security+`}</h3>
    <Cert logo="resized/SecurityPlus_Logo_Certified_CE.png" link="https://certification.comptia.org/certifications/security" mdxType="Cert">
The CompTIA Security+ certification addressed many IT security subjects such as: cryptography, network security, compliance, threats, access control, etc. This was a very good introduction to security subjects as they relate to computers and networks.
    </Cert>
    <hr></hr>
    <h3><a name='ceh'></a>{`Certified Ethical Hacker (C|EH)`}</h3>
    <Cert logo="changed_format/CEH_logo.png" link="https://www.eccouncil.org/Certification/certified-ethical-hacker" mdxType="Cert">
The Certified Ethical Hacker certification covered many areas of computer security including: footprinting, scanning, enumeration, penetration testing, SQL injection, etc. By gaining a better understanding of the tactics use by ethical hackers, I acquired valuable information to facilitate building better defenses and more secure web applications.
    </Cert>
    <hr></hr>
    <h3><a name='pce'></a>{`Zend Certified PHP Engineer (ZCPE)`}</h3>
    <Cert link="https://www.zend.com/en/services/certification/php-certification" mdxType="Cert">
The Zend Certified PHP Engineer certification required exhaustive knowledge of the PHP language. Many of the areas that were tested included: functions, arrays, object oriented programming, security, strings, program logic, error detection, debugging, etc.
    </Cert>
    <p><a parentName="p" {...{
        "href": "https://www.zend.com/en/yellow-pages/ZEND024785"
      }}>{`Josh Young - ZCPE`}</a></p>
    <hr></hr>
    <h3><a name='network'></a>{`CompTIA Network+`}</h3>
    <Cert logo="resized/NetworkPlus_Logo_Certified_CE.png" link="https://certification.comptia.org/certifications/network" mdxType="Cert">
The CompTIA Network+ certification required knowledge in many network related areas. Here are a few of the subjects covered: routing, network topologies, virtualization, network segmentation, switch port security, user authentication, firewalls, network access control, etc.
    </Cert>
    <hr></hr>
    <h3><a name='professional'></a>{`CIW Web Development Professional`}</h3>
    <Cert link="http://www.ciwcertified.com/certifications/Web_Development_Series/development.php" mdxType="Cert">
The CIW Web Development Professional certification was awarded as a result of achieving the following certifications: CIW JavaScript Specialist, CIW Perl Specialist, and CIW Database Design Specialist.
    </Cert>
    <hr></hr>
    <h3><a name='database'></a>{`CIW Database Design Specialist`}</h3>
    <Cert link="http://www.ciwcertified.com/Certifications/Web_Development_Series/database_design.php" mdxType="Cert">
The CIW Database Design Specialist certification covered many areas of database design and optimization including: planning, relational algebra, design methodology, normalization, Structured Query Language (SQL), database security, etc.
    </Cert>
    <hr></hr>
    <h3><a name='perl'></a>{`CIW Perl Specialist`}</h3>
    <Cert link="http://www.ciwcertified.com/Certifications/Web_Development_Series/perl.php" mdxType="Cert">
The CIW Perl Specialist certification required knowledge in the following areas of the Perl language: loops, regular expressions, the interpreter, hashes, subroutines, reading/writing data, modules, object oriented programming techniques, database interaction, debugging, etc.
    </Cert>
    <hr></hr>
    <h3><a name='javascript'></a>{`CIW JavaScript Specialist`}</h3>
    <Cert link="http://www.ciwcertified.com/Certifications/Web_Development_Series/javascript.php" mdxType="Cert">
The CIW JavaScript Specialist certification covered the JavaScript language
including topics such as: program flow, debugging, validation, DOM manipulation, libraries, AJAX, JavaScript security, libraries, etc.
    </Cert>
    <hr></hr>
    <h3><a name='linux'></a>{`CompTIA Linux+`}</h3>
    <Cert logo="resized/LinuxPlus_Logo_Certified.png" link="https://certification.comptia.org/certifications/linux" mdxType="Cert">
The CompTIA Linux+ certification required a very thorough knowledge of Linux. Here are just a few of the knowledge areas: file systems, run levels, package management, file management, process manipulation, command line regular expressions, scripting, security, shells, network configuration, and scheduled task configuration.
    </Cert>
    <hr></hr>
    <h3><a name='suse'></a>{`SUSE Certified Linux Administrator 11`}</h3>
    <Cert logo="resized/cert_linux_admin_11_half.png" link="https://training.suse.com/certification/sca-linux" mdxType="Cert">
This SUSE cert was attained through passing a Linux exam. This certification validates my skill for SUSE Linux 11.
    </Cert>
    <hr></hr>
    <h3><a name='lpci'></a>{`LPIC-1`}</h3>
    <Cert link="https://certification.comptia.org/certifications/linux" mdxType="Cert">
The LPIC-1 certification was all about Linux fundamentals. It covered many Linux areas including: symbolic links, permissions, disk management, file system integrity, process execution, GNU commands, libraries, boot loader configuration, etc.
    </Cert>
    <p><a parentName="p" {...{
        "href": "https://www.lpi.org/certification/get-certified-lpi/lpic-1-linux-server-professional"
      }}>{`About LPIC Certification`}</a></p>
    <hr></hr>
    <h3><a name='aplus'></a>{`CompTIA A+`}</h3>
    <Cert logo="resized/Aplus_Logo_Certified_CE.png" link="https://certification.comptia.org/certifications/a" mdxType="Cert">
The CompTIA A+ certification required knowledge in many areas of computer hardware including: BIOS configuration, motherboards, RAM, installation and configuration of computers and peripherals, network cable types, TCP/IP, TCP/UDP ports, networking tools, and troubleshooting.
    </Cert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      